.departments {
  list-style-type: none;
  font-size: 1.2em; }
  .departments li {
    margin-bottom: 2px; }

.pull-up {
  margin-bottom: -5px; }

.progression {
  margin: 25px 0; }

.clickable {
  cursor: pointer; }

.estimatedTotal {
  padding: 7px 0;
  margin-top: 10px;
  border-top: solid 3px #999; }

.blackBookRequest img {
  margin-right: 20px; }

.blackBookRequest .lead {
  margin-bottom: 2px; }

.summary .tooltip-wrapper {
  display: inline-block; }
  .summary .tooltip-wrapper .btn[disabled] {
    pointer-events: none; }

.summary .cart-table .inline {
  float: left; }
  .summary .cart-table .inline .btn-danger {
    height: 35px; }
  .summary .cart-table .inline.input-group {
    width: 120px; }

.summary .cart-table .input-group-addon {
  background-color: #0061A0;
  border: 0;
  color: #FFF;
  font-size: 1em;
  padding: 0px 4px; }
  .summary .cart-table .input-group-addon i {
    cursor: pointer;
    margin: 0;
    padding: 6px 6px 4px; }
    .summary .cart-table .input-group-addon i[disabled="disabled"] {
      color: #666;
      cursor: not-allowed; }

.summary .cart-table td {
  vertical-align: center; }
  .summary .cart-table td.qty {
    width: 130px;
    text-align: center; }
  .summary .cart-table td .product-img {
    margin-right: 15px;
    max-width: 100px;
    max-height: 100px; }

.summary .cart-table .summaryRow td {
  border: none;
  padding-top: 0;
  padding-bottom: 0; }

.summary .cart-table .summaryStart {
  background-color: #eee;
  border-top: solid 2px #999; }
  .summary .cart-table .summaryStart td {
    padding: 8px; }

.summary .departments {
  list-style-type: none;
  padding-left: 0; }
  .summary .departments li {
    margin-bottom: 3px;
    font-size: 1.1em; }

@media screen and (max-width: 576px) {
  .summary .cart-table .inline.input-group {
    width: 60px; }
    .summary .cart-table .inline.input-group span.input-group-addon {
      display: none; }
  .summary .cart-table td.qty {
    width: auto; } }

.checkoutAs .thumbnail {
  height: 300px; }

.review .review-lead {
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: solid 1px #ccc;
  font-size: 1.4em; }

.review .sub-head {
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 1.1em; }

.review .cart-table td {
  vertical-align: center; }
  .review .cart-table td .quantity {
    width: 70px; }
  .review .cart-table td .product-img {
    margin-right: 10px;
    max-width: 80px;
    max-height: 80px; }

.flexer, .progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.no-flexer, .progress-indicator.stacked {
  display: block; }

.no-flexer-element {
  -ms-flex: 0;
  -webkit-flex: 0;
  -moz-flex: 0;
  flex: 0; }

.flexer-element, .progress-indicator > li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1; }

.progress-indicator {
  margin: 0;
  padding: 0;
  font-size: 85%;
  margin-bottom: 1em; }
  .progress-indicator > li {
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: #ccc;
    display: block; }
    .progress-indicator > li:hover {
      color: gray; }
  .progress-indicator > li .bubble {
    height: 12px;
    width: 99%;
    background-color: #ccc;
    display: block;
    margin: 0 auto 0.5em auto; }
  .progress-indicator > li.completed {
    color: #5cb85c; }
    .progress-indicator > li.completed .bubble {
      background-color: #5cb85c;
      color: #5cb85c;
      border-color: #255625; }
      .progress-indicator > li.completed .bubble:before, .progress-indicator > li.completed .bubble:after {
        background-color: #5cb85c;
        border-color: #255625; }
  .progress-indicator > li.active {
    color: #ccc; }
    .progress-indicator > li.active .bubble {
      background-color: #ccc;
      color: #ccc;
      border-color: gray; }
      .progress-indicator > li.active .bubble:before, .progress-indicator > li.active .bubble:after {
        background-color: #ccc;
        border-color: gray; }
  .progress-indicator > li a:hover .bubble {
    background-color: #ccc;
    color: #ccc;
    border-color: gray; }
    .progress-indicator > li a:hover .bubble:before, .progress-indicator > li a:hover .bubble:after {
      background-color: #ccc;
      border-color: gray; }
  .progress-indicator > li.danger .bubble {
    background-color: #d9534f;
    color: #d9534f;
    border-color: #761c19; }
    .progress-indicator > li.danger .bubble:before, .progress-indicator > li.danger .bubble:after {
      background-color: #d9534f;
      border-color: #761c19; }
  .progress-indicator > li.warning .bubble {
    background-color: #f0ad4e;
    color: #f0ad4e;
    border-color: #985f0d; }
    .progress-indicator > li.warning .bubble:before, .progress-indicator > li.warning .bubble:after {
      background-color: #f0ad4e;
      border-color: #985f0d; }
  .progress-indicator > li.info .bubble {
    background-color: #34699a;
    color: #34699a;
    border-color: #0e1b28; }
    .progress-indicator > li.info .bubble:before, .progress-indicator > li.info .bubble:after {
      background-color: #34699a;
      border-color: #0e1b28; }
  .progress-indicator.stacked > li {
    text-indent: -10px;
    text-align: center;
    display: block; }
  .progress-indicator.stacked > li .bubble:before,
  .progress-indicator.stacked > li .bubble:after {
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%; }
  .progress-indicator.stacked .stacked-text {
    position: relative;
    z-index: 10;
    top: 0;
    margin-left: 60% !important;
    width: 45% !important;
    display: inline-block;
    text-align: left;
    line-height: 1.2em; }
  .progress-indicator.stacked > li a {
    border: none; }
  .progress-indicator.stacked.nocenter > li .bubble {
    margin-left: 0;
    margin-right: 0; }
  .progress-indicator.stacked.nocenter > li .bubble:before,
  .progress-indicator.stacked.nocenter > li .bubble:after {
    left: 10px; }
  .progress-indicator.stacked.nocenter .stacked-text {
    width: auto !important;
    display: block;
    margin-left: 40px !important; }

@media handheld, screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 60%; } }

.btn_ShopperApproved {
  cursor: pointer;
  color: #108DE6;
  font-size: 14px;
  margin-top: 17px;
  background-color: #d4f1dc;
  line-height: 40px;
  display: block;
  width: 100%;
  text-align: center; }

#promoCodesContainer {
  position: relative; }
  #promoCodesContainer .label {
    font-size: inherit !important;
    border-radius: .50em !important;
    font-weight: normal !important;
    margin: 2px 5px 0 0;
    display: inline-block;
    padding: .5em .6em;
    vertical-align: middle !important; }

#swpPlugin iframe {
  border: 0; }

.shipToSelect ul.dropdown-menu {
  max-height: 10em;
  max-width: 100%;
  overflow: scroll; }
